.header {
    background-image: url(../../images/images/georgia-de-lotz--UsJoNxLaNo-unsplash.png);
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 80px;
    max-width: 100%;
    color: white;
    border-bottom: 1px solid #FFFFFF;
    border-color: white;
    padding: 19px 104px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    top: 0;
    z-index: 6;
    transition: 0.1s linear 0.2s;
}

.header_theme_black {
    color: black;
    border-color: black;
    background-color: white;
    background-image: none;
}

.header__logo {
    transition: 0.1s linear 0.1s;
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.25;
    text-decoration: none;
    color: white;
    align-self: center;
}

.header__logo-black {
    color: black;
}

.header__logo:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media screen and (max-width: 920px){
    .header{
        padding: 3px 40px 0;
    }
}

@media screen and (max-width: 690px){
    .header{
        align-items: center;
        padding: 16px;
        position: sticky;
        height: 24px;
    }

    .header-sticky {
        background-image: none;
        background-color: #1A1B22;
    }

    .header-sticky_theme_black {
        background-color: white;
    }

    .header__logo {
        font-size: 16px;
        line-height: 1.5;
    }
}