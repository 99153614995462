.saved-news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    max-width: 100%;
}

.news-card-list {
    max-width: 100vw;
    padding: 62px 102px;
    background-color: #F5F6F7;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.news-card-list__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

@media screen and (max-width: 720px){
    .news-card-list {
        padding: 24px 16px 13px;
    }

    .news-card-list__container {
        max-width: 288px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}