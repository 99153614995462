.preloader {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: 0.3s linear 0.1s;
}

.preloader__icon {
    width: 100px;
    height: 100px;
}
