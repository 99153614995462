@font-face {
    font-family: 'roboto-slab';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("./Roboto-Slab-font/Roboto-Slab/roboto-slab-v24-latin-regular.woff2") format("woff2"),
         url("./Roboto-Slab-font/Roboto-Slab/roboto-slab-v24-latin-regular.woff") format("woff");
}

@font-face {
    font-family: 'roboto-slab';
    font-style:  normal;
    font-weight: 700;
    font-display: swap;
    src: url("./Roboto-Slab-font/Roboto-Slab/roboto-slab-v24-latin-700.woff2") format("woff2"),
         url("./Roboto-Slab-font/Roboto-Slab/roboto-slab-v24-latin-700.woff") format("woff");
}
