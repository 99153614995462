.nav {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0px;
    text-align: center;
    display: flex;
    flex-direction: row;
    transition: 0.1s linear 0.2s;
    max-width: 100%;
}

.nav__link {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    margin-right: 50px;
    align-self: center;
    color: #FFFFFF;
    background: none;
    text-decoration: none;
    opacity: 0.7;
    transition: 0.1s linear 0.2s;
}

.nav__link-login {
    height: 48px;
    margin-bottom: 5px;
    border-radius: 100px;
    padding: 12px 50px;
    background: none;
    color: white;
    border: 1px solid #FFFFFF;
    transition: 0.1s linear 0.1s;
}

.nav__link_theme_black  {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    color: black;
}

.nav__link_marked-white {
    border-bottom: 3px solid #FFFFFF;
    opacity: 1;
    padding: 29px 10px;
}

.nav__link_marked-black {
    border-bottom: 3px solid black;
    padding: 29px 10px;
}

.nav__login_theme_black  {
    color: black;
    border: 1px solid black;
}

.nav__link-logout {
    height: 48px;
    margin-bottom: 5px;
    border-radius: 100px;
    padding: 12px 50px;
    color: white;
    border: 1px solid #FFFFFF;
    transition: 0.1s linear 0.1s;
    background: none;
    padding: 12px 19px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 19px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.nav__link-logout_icon{
    height: 20px;
    width: 20px;
}

.nav__logout_theme_black {
    color: black;
    border: 1px solid black;
}

.nav__link-login:hover {
    opacity: 0.7;
}

.nav__link:hover {
    opacity: 1;
    cursor: pointer;
}

.nav__menu {
    list-style: none;
    display: flex;
    margin: 13px 0;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: #FFFFFF;
    padding: 0;
}

.nav__overlay{
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: black;
    Opacity: 50%;
    top: -100%;
    visibility: hidden;
    transition: 0.3s;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.hamburger__bar {
    display: block;
    width: 16px;
    height: 2px;
    margin: 2px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #FFFFFF;
}

.hamburger__bar_theme_black  {
    background-color: #000;
}

@media screen and (max-width: 920px){
    .nav{
        max-height: 66px;
    }
    .nav__link {
        font-size: 19px;
        text-align: center;
        margin-right: 16px;
        align-self: center;
        background: none;
        text-decoration: none;
        transition: 0.1s linear 0.2s;
    }
    .nav__link_marked-white {
        border-bottom: 3px solid #FFFFFF;
        opacity: 1;
        padding: 21px 10px;
    }
    
    .nav__link_marked-black {
        border-bottom: 3px solid black;
        padding: 21px 10px;
    }
}

@media screen and (max-width: 690px){
    .nav{
        max-height: 56px;
    }
    .hamburger {
        display: block;
    }

    .hamburger_active .hamburger__bar:nth-child(1) {
        transform: translateY(2px) rotate(45deg);
    }

    .hamburger_active .hamburger__bar:nth-child(2) {
        transform: translateY(-2px) rotate(-45deg);
    }

    .nav__menu{
        position: fixed;
        flex-direction: column;
        align-items: flex-start;
        top: -100%;
        left: 0;
        width: 100%;
        text-align: left;
        transition: 0.3s;
        background-color: #1A1B22;
    }

    .nav__link-login {
        width: 285px;
    }

    .nav__login_theme_black {
        padding: 12px 19px;
        display: flex;
        gap: 10px;
        flex-direction: row;
        justify-content: center;
    }
    
    .nav__overlay_active {
        visibility: visible;
        top: 303px;
    }

    .nav__overlay_active-loggedIn {
        top: 230px;
    }

    .nav__menu_theme_black {
        background-color: #FFFFFF;
        color: #000;
    }

    .menu__item {
        margin: 24px 16px
    }

    .nav__menu_active {
        top: 44px;
    }

    .nav__link_marked-black {
        border-bottom: none;
    }

    .nav__link_marked-white {
        border-bottom: none;
    }
}