.search-form {
    background-image: url(../../images/images/georgia-de-lotz--UsJoNxLaNo-unsplash.png);
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 100%;
    padding: 80px auto;
    margin: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

.search-form__title {
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 60px;
    font-weight: 400;
    line-height: 1.06;
    letter-spacing: 0px;
    text-align: left;
    margin: 80px auto 32px;
    max-width: 608px;
}

.search-form__subtitle {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 88px;
    max-width: 608px;
}

.search-form__form {
    width: 608px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 80px;
}

.search-form__input {
    max-height: 64px;
    width: 100%;
    border-radius: 100px;
    border: none;
    padding: 21px 24px;
    margin: none;
}

.search-form__error {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    color: #FF0000;
    margin: 5px 0 13px;
    visibility: hidden;
}

.search-form__error_visible{
    visibility: visible;
}

.search-form__button {
    max-height: 64px;
    width: 168px;
    padding: 20px 56px;
    border-radius: 100px;
    border: none;
    margin: none;
    margin-left: -164px;
    background: #2F71E5;
    transition: 0.1s linear 0.1s;
    position: relative;
}

.search-form__button_text {
    color: white;
}

.search-form__button:hover {
    background: #347EFF;
}

.search-form__button:focus {
    background: #2A65CC;
}

.search-results {
    padding: auto;
    background-color: #F5F6F7;
    display: flex;
    margin: 0 auto 0;
    align-items: center;
    transition: 0.2s linear 0.1s;
    max-width: 100%;
    align-items: center;
}

.search-form__button_loading .search-form__button_text {
    visibility: hidden;
    opacity: 0;
}

.search-form__button_loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid transparent;
    border-top-color: white;
    border-radius: 50%;
    animation: search-form__button_loading_spinner 1s ease-in-out infinite;
}

@keyframes search-form__button_loading_spinner {
    from {
        transform: rotate(0turn)
    }
    to {
        transform: rotate(1turn)
    }
}

@media screen and (max-width: 920px){
    .search-form__title {
        font-size: 36px;
        line-height: 1.1;
        max-width: 452px;
        margin: 38px auto 16px;
    }

    .search-form__subtitle {
        max-width: 452px;
        margin: 0 0 48px;
    }

    .search-form__form {
        max-width: 448px;
        margin-bottom: 48px;
    }

    .search-form__button {
        width: 160px;
        margin-left: -159px;
    }
}

@media screen and (max-width: 620px){
    .search-form__title {
        max-width: 285px;
        margin-top: 30px;
    }

    .search-form__subtitle {
        max-width: 285px;
        margin-bottom: 122px;
    }

    .search-form__form {
        max-width: 285px;
        flex-direction: column;
        align-items: center;
        padding: 0 16px;
        margin-bottom: 32px;
    }

    .search-form__input {
        max-width: 90%;
        padding: 17px 16px;
        margin-bottom: 16px;
    }
    
    .search-form__button {
        width: 100%;
        margin: 0;
    }
}