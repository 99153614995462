.popup-with-form {
    position: fixed;
    bottom: 0%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto 0;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s linear;
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 7;
}

.popup-with-form_visible {
    visibility: visible;
    opacity: 1;
}

.popup-with-form__container {
    background: #fff;
    max-width: 430px;
    padding: 34px 36px 28px;
    border-radius: 16px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    color: #000;
    margin: 0 auto 0;
    z-index: 8;
    display: flex;
    flex-direction: column;
}

.popup-with-form__close-button {
    background: url(../../images/icons/close-icon/large.svg) center no-repeat;
    transition: 0.1s linear 0.1s;
    align-self: flex-end;
    cursor: pointer;
    border: none;
    margin: -80px;
    padding: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
    position: fixed;
}

.popup__close-button:hover {
    opacity: 0.8;
}

.form {
    color: #000;
    max-width: 100%;
    padding: 0;
}

.form__title {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.16;
    height: 32px;
    max-width: 320px;
    margin: 0 0 22px;
}

.form__fieldset {
    border: none;
    width: 358px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
}

.form__input-container {
    margin-bottom: 30px;
}

.form__input-title {
    font-family: 'inter', Arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
    color: #2F71E5;
    margin: 0 0 9px;
}

.form__input {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    background-color: transparent;
    width: 358px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
}

.form__input:focus {
    border: 0;
    border-bottom: 1px solid #000;
    outline: none;
}

.form__error {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25;
    text-align: left;
    color: #FF0000;
    margin: 5px 0 13px;
}

.form__button {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    height: 64px;
    width: 360px;
    border-radius: 100px;
    border: none;
    background: #2F71E5;
    box-shadow: 0px 5px 15px 0px #0E1A3933;
    color: #fff;
}

.form__button:hover {
    background: #347EFF;
    cursor: pointer;
}

.form__button_disabled {
    background: #E6E8EB;
    box-shadow: 0;
    color: #B6BCBF;
    pointer-events: none
}

.form__button_disabled:hover {
    background: #E6E8EB;
    cursor: default;
}

.form__switch {
    font-family: 'inter', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    color: #000;
    align-self: center;
    margin-bottom: 0;
}

.form__switch-link {
    font-family: 'inter', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    color: #2F71E5;
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
}

.form__switch-link-tooltip {
    justify-self: flex-start;
    text-align: left;
    margin-top: 10px;
}

@media screen and (max-width: 520px){
    .popup-with-form {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        bottom: -100%;
        max-width: 100vh;
        height: 100%;
        transition: 0.3s;
        z-index: 8;
        padding-top: 70px;
        justify-content: flex-end;
    }

    .popup-with-form_visible {
        bottom: 0;
    }

    .popup-with-form__close-button {
        background: url(../../images/icons/close-icon/small.svg) center no-repeat;
        margin: -52px -2px;
        width: 13px;
        height: 13px;
    }

    .popup-with-form__container {
        padding: 16px 16px 0;
        max-width: 288px;
        height: 90vh;
        border-radius: 16px 16px 0px 0px;
    }

    .form__title {
        margin: 0 0 18px;
    }

    .form__input {
        max-width: 288px;
    }

    .form__button {
        max-width: 288px;
    }

    .form {
        max-width: 288px;
    }

    .form__fieldset {
        border: none;
        width: 320px;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }

    .popup-with-form__overlay {
        top: 0%;
        background-color: #000;
        z-index: 7;
    }
}