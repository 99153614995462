.news-card {
    max-width: 400px;
    max-height: 600px;
    background: #FFFFFF;
    border-radius: 16px;
    position: relative;
    transition: 0.2s linear 0.1s;
}

.news-card:hover {
    box-shadow: 5px 10px 8px #081D2D1A;
}

.news-card__top {
    width: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    padding: 10px 0;
    justify-content: space-between;
    flex-wrap: wrap;
}

.news-card__image {
    width: 100%;
    max-height: 272px;
    border-radius: 16px 16px 0px 0px;
}

.news-card__icon {
    display: flex;
    position: relative;
    visibility: hidden;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;
    z-index: 5;
}

.news-card__icon:hover {
    visibility: visible;
}

.news-card__button {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    background-color: white;
    border: none;
    visibility: visible;
    margin: 0;
    margin-left: 5px;
    cursor: pointer;
}

.news-card__tooltip {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.15;
    padding: 13px 26px;
    border-radius: 10px;
    background-color: white;
    transition: 0.2s linear 0.1s;
}

.news-card__keyword {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.7;
    padding: 8px 22px;
    border-radius: 10px;
    background-color: white;
    z-index: 5;
    margin-left: 24px;
    visibility: hidden;
}

.news-card__keyword_visible {
    visibility: visible;
}

.news-card__button_type_trash {
    background-image: url('../../images/icons/Delete/normal.svg');
    transition: 0.2s linear 0.1s;
}

.news-card__button_type_trash:hover {
    background-image: url('../../images/icons/Delete/hover.svg');
}

.news-card__button_type_bookmark {
    background-image: url('../../images/icons/Bookmark/Regular.svg');
    transition: 0.2s linear 0.1s;
}

.news-card__button_type_bookmark:hover {
    background-image: url('../../images/icons/Bookmark/Hover.svg');
}

.news-card__button_type_bookmarked {
    background-image: url('../../images/icons/Bookmark/marked.svg');
}

.news-card__button_type_bookmarked:hover {
    background-image: url('../../images/icons/Bookmark/marked.svg');
}

.news-card__bottom {
    padding: 17px 24px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 352px;
    align-items: flex-start;
}

.news-card__date {
    font-family: 'Source Sans Pro', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    color: #B6BCBF;
    margin: 0 0 12px;
}

.news-card__title {
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 26px;
    font-weight: 400;
    line-height: 1.15;
    margin: 0 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
    max-height: 60px;
}

.news-card__text {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375;
    margin: 0 0 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    height: 110px;
}

.news-card__source {
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #B6BCBF;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 352px;
    height: 20px;
    margin: 0 0 24px;
    overflow: hidden;
}

.news-card__link {
    text-decoration: none;
}

@media screen and (max-width: 1220px){
    .news-card {
        max-width: 280px;
        height: 420px;
    }

    .news-card__tooltip {
        display: none;
    }

    .news-card__top {
        padding: 0;
    }

    .news-card__icon {
        padding: 0 8px
    }

    .news-card__keyword {
        margin: 8px;
        padding: 8px 20px;
    }

    .news-card__image {
        height: 150px;
        max-height: 300px;
        border-radius: 16px 16px 0px 0px;
    }

    .news-card__bottom {
        padding: 17px 24px 0;
    }

    .news-card__source {
        max-width: 200px;
    }
}

@media screen and (max-width: 920px){
    .news-card {
        max-width: 224px;
    }

    .news-card__title {
        font-size: 22px;
    }

    .news-card__text {
        margin: 0 0 12px;
    }

    .news-card__tooltip {
        display: none;
    }

    .news-card__top {
        padding: 0;
    }

    .news-card__icon {
        padding: 0 8px;
        margin-top: 8px;
    }

    .news-card__keyword {
        margin: 8px;
        padding: 8px 20px;
    }

    .news-card__image {
        height: 150px;
        max-height: 272px;
        border-radius: 16px 16px 0px 0px;
    }

    .news-card__bottom {
        padding: 17px 24px 0;
        max-height: 100%;
    }

    .news-card__source {
        max-width: 192px;
        margin: 0;
        justify-self: flex-start;
    }
}

@media screen and (max-width: 720px){
    .news-card {
        max-width: 288px;
        height: 440px;
    }
}