.about {
    color: black;
    background-color: white;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

.about__container{
    margin: 80px auto;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 32px;
    max-width: 90%;
}

.about__image {
    height: 464px;
    width: 464px;
    border-radius: 1000px;
    background-image: url(../../images/images/IMG_2626.jpg);
    background-position: center;
    background-size: cover;
    margin-right: 60px;
}

.about__title {
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.15;
    margin-bottom: 24px;
}

.about__text {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0px;
    text-align: left;
}

.about__description {
    max-width: 600px;
}

@media screen and (max-width: 920px){
    .about__image {
        height: 232px;
        width: 232px;
        margin-right: 32px;
        align-self: center;
    }
    .about__title {
        font-size: 30px;
    }
    .about__container{
        margin: 40px auto 61px;
    }
}

@media screen and (max-width: 690px){
    .about__image {
        height: 272px;
        width: 272px;
        margin-right: 0;
    }
    .about__container{
        margin: 32px auto 108px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}