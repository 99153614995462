@font-face {
    font-family: 'roboto';
    font-style:  normal;
    font-weight: 400;
    font-display: swap;
    src: url("./Roboto/roboto-v30-latin-regular.woff2") format("woff2"),
         url("./Roboto/roboto-v30-latin-regular.woff") format("woff");
}

@font-face {
    font-family: 'roboto';
    font-style:  normal;
    font-weight: 500;
    font-display: swap;
    src: url("./Roboto/roboto-v30-latin-500.woff2") format("woff2"),
         url("./Roboto/roboto-v30-latin-500.woff") format("woff");
}

@font-face {
    font-family: 'roboto';
    font-style:  normal;
    font-weight: 900;
    font-display: swap;
    src: url("./Roboto/roboto-v30-latin-900.woff2") format("woff2"),
         url("./Roboto/roboto-v30-latin-900.woff") format("woff");
}
