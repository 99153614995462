.app {
  margin: 0;
  padding: 0;
  font-family: 'inter', Arial, sans-serif, 'roboto-slab', 'roboto';
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.content {
    min-width: 320px;
    min-height: 100vh;
}