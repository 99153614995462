.search-results__container {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
}

.search-results__title {
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 46px;
    margin: 80px 0 64px;
    align-self: flex-start;
}

.search-results__list {
    margin: 0;
    max-width: 1236px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
}

.search-results__button {
    position: relative;
    height: 64px;
    width: 288px;
    border-radius: 80px;
    border: none;
    background-color: #FFFFFF;
    margin: 64px auto 80px;
    transition: 0.2s linear 0.1s;
    align-self: center;
}

.search-results__button:hover {
    background-color: #E8E8E8;
}

.search-results__button_text {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
}

@media screen and (max-width: 920px){
    .search-results__title {
        font-size: 30px;
        margin: 40px 0 32px;
    }

    .search-results__button {
        margin: 32px auto 40px;
    }
}

@media screen and (max-width: 720px){
    .search-results__list {
        max-width: 288px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 620px){
    .search-results__title {
        margin: 32px 0 58px;
    }

    .search-results__button {
        margin: 21px auto 24px;
    }
}