.tooltipPopup {
    position: fixed;
    bottom: 0%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto 0;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s, visibility 0.5s linear;
    padding: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    z-index: 7;
}

.tooltipPopup_visible {
    visibility: visible;
    opacity: 1;
}

.tooltipPopup__container {
    background: #fff;
    max-width: 430px;
    padding: 34px 36px 28px;
    border-radius: 16px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    color: #000;
    margin: 0 auto 0;
    z-index: 8;
    display: flex;
    flex-direction: column;
}

.tooltipPopup__close-button {
    background: url(../../images/icons/close-icon/large.svg) center no-repeat;
    transition: 0.1s linear 0.1s;
    align-self: flex-end;
    cursor: pointer;
    border: none;
    margin: -80px;
    padding: 0;
    width: 40px;
    height: 40px;
    z-index: 1;
    position: fixed;
}

.tooltipPopup__close-button:hover {
    opacity: 0.8;
}

.tooltipPopup__textContainer_title {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.16;
    height: 32px;
    max-width: 320px;
    margin: 0 0 22px;
}

.tooltipPopup__textContainer-link {
    font-family: 'inter', Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
    color: #2F71E5;
    border: none;
    background: none;
    cursor: pointer;
    padding: 5px;
    margin-top: 20px;
}