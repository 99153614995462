.saved-news__header {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    padding: 40px 104px 56px;
    align-items: flex-start;
}

.saved-news__top-title {
    max-height: 24px;
    max-width: 712px;
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #1A1B2280;
    align-self: flex-start;
    margin: 0;
}

.saved-news__title {
    max-height: 92px;
    max-width: 530px;
    font-family: 'roboto-slab', 'inter', Arial, sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 1.15;
    text-align: left;
    margin: 28px 0 30px;
}

.saved-news__subtitle {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin: 0;
}

.saved-news__keywords {
    font-family: 'roboto', 'inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    padding-left: 5px;
}

@media screen and (max-width: 920px){
    .saved-news__header {
        padding: 24px 40px 32px;
    }
    
    .saved-news__top-title {
        font-size: 14px;
    }
    
    .saved-news__title {
        font-size: 30px;
        margin: 16px 0;
    }
}

@media screen and (max-width: 690px){
    .saved-news__header {
        padding: 8px 16px 32px;
    }

    .saved-news__title {
        font-size: 30px;
        margin: 16px 0 50px;
    }
}